import braintree from 'braintree-web';
import $ from 'jquery';
import Payment from 'payment';
import paypal from 'paypal-checkout';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, clearSubmitErrors, Field, Form, formValueSelector, reduxForm } from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import MessageBox from 'components/MessageBox/index';
import { fetchToken as fetchClientToken } from 'entities/Payment/actions';
import { renderEnhancedField } from 'util/form/renderers';
import { formatExpiration, formatZip, normalizeNumber } from 'util/formatters';
import './styles.scss';

/* Pay form. */
class PayForm extends BaseForm {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // keep track of field focus
    this.state = {
      ...this.state,
      focused: '',
    };

    // keep track of payment type
    this.state = {
      ...this.state,
      paymentType: undefined,
    };

    // keep track of which payment types are supported
    this.state = {
      ...this.state,
      creditCardEnabled: false,
      payPalEnabled: false,
      payPalSupported: true,
      payPalClient: null,
      payPalClientError: false,
      googlePayEnabled: false,
      googlePaySupported: true,
      googlePayClient: null,
      googlePayClientError: false,
      applePayEnabled: false,
      applePaySupported: true,
      applePayClient: null,
      applePayClientError: false,
    };

    // non-CC payments require a token
    this.state = {
      ...this.state,
      clientToken: null,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // if available, default to a CC payment
    if (this.props.status?.payments?.creditCard) {
      this.setState({ creditCardEnabled: true });
      this.setState({ paymentType: 'creditCard' });
      this.props.setPaymentType('creditCard');
    }

    // non-CC payments are not supported if the amount is 0 (which it never should be)
    if (this.props.amount > 0) {
      // we need a payment token for non-CC payment types
      this.props.loadClientToken().then((token) => {
        // capture the token
        this.setState({ clientToken: token });

        // PayPal
        if (this.props.status?.payments?.payPal) {
          // it's enabled
          this.setState({ payPalEnabled: true });

          // set up the client
          braintree.client
            .create({
              authorization: token,
            })
            .then((clientInstance) => {
              return braintree.paypalCheckout
                .create({
                  client: clientInstance,
                })
                .then((client) => {
                  // capture the client
                  this.setState({ payPalClient: client });

                  // we need to bind to 'this' because we won't have it later
                  const instance = this;

                  // render the button
                  if ($('#paypal-button').length) {
                    try {
                      paypal.Button.render(
                        {
                          style: {
                            size: 'large',
                            color: 'blue',
                            shape: 'rect',
                            label: 'paypal',
                            tagline: false,
                          },
                          env: instance.props.testMode ? 'sandbox' : 'production',
                          commit: true,
                          payment: function () {
                            return client.createPayment({
                              flow: 'checkout',
                              amount: instance.props.amount,
                              currency: 'USD',
                              enableShippingAddress: false,
                            });
                          },
                          onAuthorize: function (data, actions) {
                            // get the nonce
                            return client.tokenizePayment(data).then(function (payload) {
                              // capture it on the form
                              if (payload.nonce) {
                                instance.props.dispatch(
                                  change(this.props.form, 'payment.nonce', payload.nonce),
                                );
                              }
                            });
                          },
                        },
                        '#paypal-button',
                      );
                    } catch (err) {
                      console.error('Error rendering PayPal button', err);
                      this.setState({ payPalClientError: true });
                    }
                  }
                })
                .catch((err) => {
                  console.error('Error finalizing PayPal client', err);
                  this.setState({ payPalClientError: true });
                });
            })
            .catch((err) => {
              console.error('Error initializing PayPal client', err);
              this.setState({ payPalClientError: true });
            });
        }

        // Google Pay
        if (this.props.status?.payments?.googlePay) {
          // it's enabled
          this.setState({ googlePayEnabled: true });
        }

        // Apple Pay
        try {
          if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
            if (this.props.status?.payments?.applePay) {
              // it's enabled; check if the user has an Apple Pay card available
              window.ApplePaySession.canMakePaymentsWithActiveCard(
                process.env.REACT_APP_APPLE_PAY_MERCHANT_ID,
              ).then((canMakePaymentsWithActiveCard) => {
                if (canMakePaymentsWithActiveCard) {
                  // everything is good to go
                  this.setState({ applePayEnabled: true });

                  // set up the client
                  braintree.client
                    .create({
                      authorization: token,
                    })
                    .then((clientInstance) => {
                      return braintree.applePay
                        .create({
                          client: clientInstance,
                        })
                        .then((client) => {
                          // we're good; capture the client
                          this.setState({ applePayClient: client });
                        })
                        .catch((err) => {
                          console.error('Error finalizing Apple Pay client', err);
                          this.setState({ applePayClientError: true });
                        });
                    })
                    .catch((err) => {
                      console.error('Error initializing Apple Pay client', err);
                      this.setState({ applePayClientError: true });
                    });
                } else {
                  // user can't make payments with the active card
                  console.debug('User cannot make payments with the active Apple Pay card');
                  this.setState({ applePaySupported: false });
                }
              });
            }
          } else {
            // user is not allowed to use Apple Pay
            console.warn("User's browser does not support Apple Pay");
            this.setState({ applePaySupported: false });
          }
        } catch (err) {
          console.error('Error initializing Apple Pay session', err);
          this.setState({ applePayClientError: true });
        }
      });
    }
  }

  componentWillUnmount() {
    // parent, for lifecycle logging
    super.componentWillUnmount();

    // tear down the clients
    if (this.state.payPalClient) {
      this.state.payPalClient.teardown();
    }
    if (this.state.googlePayClient) {
      this.state.googlePayClient.teardown();
    }
    if (this.state.applePayClient) {
      this.state.applePayClient.teardown();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // make sure everything is formatted
    if (this.state.paymentType === 'creditCard') {
      Payment.formatCardNumber(document.querySelector('[name="payment.creditCard.number"]'));
      Payment.formatCardExpiry(document.querySelector('[name="payment.creditCard.expiration"]'));
      Payment.formatCardCVC(document.querySelector('[name="payment.creditCard.cvv"]'));
    }
  }

  loadGooglePay(token) {
    // define accepted payment methods
    const baseCardPaymentMethod = {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
      },
    };

    // set the up Google client
    const google = window.google;
    const paymentsClient = new google.payments.api.PaymentsClient({
      environment: this.props.testMode ? 'TEST' : 'PRODUCTION',
    });

    // make sure it's ready to go
    const isReadyToPayRequest = Object.assign(
      {},
      {
        apiVersion: 2,
        apiVersionMinor: 0,
      },
    );
    isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
    paymentsClient
      .isReadyToPay(isReadyToPayRequest)
      .then((response) => {
        if (response.result) {
          // set up the Braintree client
          braintree.client
            .create({
              authorization: token,
            })
            .then((clientInstance) => {
              braintree.googlePayment
                .create({
                  client: clientInstance,
                })
                .then((client) => {
                  // we need to bind to 'this' because we won't have it later
                  const instance = this;

                  // render the button
                  if ($('#googlePay-button').length) {
                    try {
                      const button = paymentsClient.createButton({
                        buttonColor: 'white',
                        buttonType: 'short',
                        onClick: (event) => {
                          // do not submit the form
                          event.preventDefault();

                          // build the payment data request
                          var paymentDataRequest = client.createPaymentDataRequest({
                            transactionInfo: {
                              currencyCode: 'USD',
                              totalPriceStatus: 'FINAL',
                              totalPrice: instance.props.amount,
                            },
                            cardRequirements: {
                              // for fraud mitigation
                              billingAddressRequired: true,
                            },
                          });

                          // merchant ID is only used in production
                          if (!instance.props.testMode) {
                            paymentDataRequest.merchantId =
                              process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID;
                          }

                          // fire the request
                          paymentsClient
                            .loadPaymentData(paymentDataRequest)
                            .then(function (paymentData) {
                              client.parseResponse(paymentData, function (err, result) {
                                if (err) {
                                  console.error('Error parsing Google Pay response', err);
                                  instance.setState({
                                    googlePayClientError: true,
                                  });
                                }
                                // capture the nonce
                                else if (result.nonce) {
                                  instance.props.dispatch(
                                    change(this.props.form, 'payment.nonce', result.nonce),
                                  );
                                }
                              });
                            })
                            .catch(function (err) {
                              if (!err.statusCode || err.statusCode !== 'CANCELED') {
                                console.error('Error initiating Google Pay request', err);
                                instance.setState({
                                  googlePayClientError: true,
                                });
                              }
                            });
                        },
                      });
                      document.getElementById('googlePay-button').appendChild(button);

                      // capture the client
                      this.setState({ googlePayClient: client });
                    } catch (err) {
                      console.error('Error rendering Google Pay button', err);
                      this.setState({ googlePayClientError: true });
                    }
                  }
                })
                .catch((err) => {
                  console.error('Error finalizing Google Pay client', err);
                  this.setState({ googlePayClientError: true });
                });
            })
            .catch((err) => {
              console.error('Error initializing Google Pay client', err);
              this.setState({ googlePayClientError: true });
            });
        } else {
          // user is not allowed to use Google Pay
          console.debug('User cannot use Google Pay');
          this.setState({ googlePaySupported: false });
        }
      })
      .catch((err) => {
        console.error('Error initializing native Google Pay client', err);
        this.setState({ googlePayClientError: true });
      });
  }

  validCardNumber(value) {
    // if not set, it's valid
    if (!value || typeof value === 'undefined') {
      document.querySelector('[name="payment.creditCard.number"]').setCustomValidity(``);
      return undefined;
    }

    // this is a cheat to hook into redux-form's validation
    // process to set an HTML5 validation error
    const valid = !value || Payment.fns.validateCardNumber(value);
    if (!valid) {
      document
        .querySelector('[name="payment.creditCard.number"]')
        .setCustomValidity(`Invalid card number`);
    } else {
      document.querySelector('[name="payment.creditCard.number"]').setCustomValidity(``);
    }

    // if valid, validate card type too
    var validType = true;
    if (value && valid) {
      const type = Payment.fns.cardType(value);
      switch (type) {
        case 'amex':
        case 'visa':
        case 'mastercard':
        case 'discover':
          break;
        default:
          validType = false;
      }
    }

    // valid type?
    if (!validType) {
      document
        .querySelector('[name="payment.creditCard.number"]')
        .setCustomValidity(`Invalid card type`);
    } else if (valid) {
      document.querySelector('[name="payment.creditCard.number"]').setCustomValidity(``);
    }

    // now do redux-form validation
    return !valid ? 'Invalid card number' : !validType ? 'Invalid card type' : undefined;
  }

  validCardExpiration(value) {
    // if not set, it's valid
    if (!value || typeof value === 'undefined') {
      document.querySelector('[name="payment.creditCard.expiration"]').setCustomValidity(``);
      return undefined;
    }

    // format it first
    value = formatExpiration(value);

    // this is a cheat to hook into redux-form's validation
    // process to set an HTML5 validation error
    let valid = !value || Payment.fns.validateCardExpiry(value);
    if (!valid) {
      document
        .querySelector('[name="payment.creditCard.expiration"]')
        .setCustomValidity(`Invalid expiration`);
    } else {
      document.querySelector('[name="payment.creditCard.expiration"]').setCustomValidity(``);
    }

    // make sure the year is reasonable, which we define as anytime from now
    // to 10 years out; note that we formatted it, so it's in "MM / YY" format now
    if (valid && value.length === 7) {
      const expYear = Number(value.substring(5, 7));
      const currentYear = Number(new Date().getFullYear().toString().substring(2, 4));
      if (expYear < currentYear || expYear > currentYear + 10) {
        document
          .querySelector('[name="payment.creditCard.expiration"]')
          .setCustomValidity(`Invalid expiration`);
        valid = false;
      } else {
        // also make sure it's not in the past in the current year
        if (expYear === currentYear) {
          const expMonth = Number(value.substring(0, 2));
          const currentMonth = new Date().getMonth() + 1;
          if (expMonth < currentMonth) {
            document
              .querySelector('[name="payment.creditCard.expiration"]')
              .setCustomValidity(`Invalid expiration`);
            valid = false;
          } else {
            document.querySelector('[name="payment.creditCard.expiration"]').setCustomValidity(``);
          }
        } else {
          document.querySelector('[name="payment.creditCard.expiration"]').setCustomValidity(``);
        }
      }
    }

    // now do redux-form validation
    return !valid ? 'Invalid expiration' : undefined;
  }

  validCardCVC(value) {
    // if not set, it's valid
    if (!value || typeof value === 'undefined') {
      document.querySelector('[name="payment.creditCard.cvv"]').setCustomValidity(``);
      return undefined;
    }

    // this is a cheat to hook into redux-form's validation
    // process to set an HTML5 validation error
    const valid = Payment.fns.validateCardCVC(value);
    if (!valid) {
      document.querySelector('[name="payment.creditCard.cvv"]').setCustomValidity(`Invalid CVC`);
    } else {
      document.querySelector('[name="payment.creditCard.cvv"]').setCustomValidity(``);
    }

    // now do redux-form validation
    return !valid ? 'Invalid CVC' : undefined;
  }

  // bind address auto-completion
  addressAutoComplete = () => ({
    keyField: 'customer.address.street',
    addressFields: {
      street: 'customer.address.street',
      street2: 'customer.address.street2',
      city: 'customer.address.city',
      state: 'customer.address.state',
      zip: 'customer.address.zip',
    },
  });

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* payment types */}
        <div className="accordion" id="paymentTypes">
          {/* credit card */}
          {this.state.creditCardEnabled && (
            <div
              className="card"
              style={
                !this.state.payPalEnabled &&
                !this.state.googlePayEnabled &&
                !this.state.applePayEnabled
                  ? {
                      // bottom card border is missing if there is only one card
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                      borderBottomRightRadius: '4px',
                      borderBottomLeftRadius: '4px',
                    }
                  : {}
              }
            >
              <div
                className="card-header png-clickable png-reservation-book-payment-type"
                id="creditCardHeader"
                aria-expanded="true"
                aria-controls="creditCard"
                onClick={() => {
                  $('#creditCard').collapse('show');
                  this.setState({ paymentType: 'creditCard' });
                  this.props.setPaymentType('creditCard');
                  this.props.clearField('payment.nonce');
                }}
              >
                Pay ${this.props.amount.toFixed(2)} with Credit Card
              </div>
              <div
                id="creditCard"
                className="collapse show"
                aria-labelledby="creditCardHeader"
                data-parent="#paymentTypes"
              >
                <div className="card-body">
                  {/* card preview */}
                  <div className="form-row png-reservation-book-payment-card-preview">
                    <Cards
                      number={this.props.number ? this.props.number : ''}
                      name={this.props.name ? this.props.name : ''}
                      expiry={this.props.expiration ? this.props.expiration.replace('/', '') : ''}
                      cvc={this.props.cvv ? this.props.cvv : ''}
                      acceptedCards={['visa', 'mastercard', 'discover', 'amex']}
                      locale={{}} /* clear locale settings */
                      focused={this.state.focused}
                    />
                  </div>

                  {/* number/name */}
                  <div className="form-row">
                    <div className="form-group col-sm-6 has-error">
                      <Field
                        label="Card Number"
                        type="text"
                        pattern="[0-9 ]*"
                        name="payment.creditCard.number"
                        autoComplete="cc-number"
                        labelClassName="col-form-label col-form-label-lg"
                        className="form-control form-control-lg"
                        component={renderEnhancedField}
                        placeholder="Card Number"
                        tooltip={`The number of the credit card being used for payment`}
                        maxLength="19"
                        validate={[this.validCardNumber]}
                        onChange={(e) => {
                          // get the type
                          var type = Payment.fns.cardType(e.target.value);
                          if (type) {
                            // need to tweak AmEx
                            if (type === 'amex') {
                              type = 'am_ex';
                            }

                            // save it on the form
                            this.props.dispatch(
                              change(this.props.form, 'payment.creditCard.issuer', type),
                            );
                          }
                        }}
                        onFocus={() => this.setState({ focused: 'number' })}
                        required={this.state.paymentType === 'creditCard'}
                        disabled={this.props.submitting}
                      />
                    </div>
                    <div className="form-group col-sm-6 has-error">
                      <Field
                        label="Name"
                        name="payment.creditCard.name"
                        autoComplete="cc-name"
                        labelClassName="col-form-label col-form-label-lg"
                        className="form-control form-control-lg"
                        component={renderEnhancedField}
                        placeholder="Name"
                        tooltip={`The name on the card being used for payment`}
                        maxLength="22"
                        onFocus={() => this.setState({ focused: 'name' })}
                        required={this.state.paymentType === 'creditCard'}
                        disabled={this.props.submitting}
                      />
                    </div>
                  </div>

                  {/* expiration/cvc */}
                  <div className="form-row">
                    {/* expiration */}
                    <div className="form-group col-sm-4 has-error">
                      <Field
                        label="Expiration"
                        name="payment.creditCard.expiration"
                        autoComplete="cc-exp"
                        labelClassName="col-form-label col-form-label-lg"
                        className="form-control form-control-lg"
                        component={renderEnhancedField}
                        placeholder="MM / YY"
                        tooltip={`The expiration month and year (MM / YY) of the card being used for payment`}
                        maxLength="7"
                        onFocus={() => this.setState({ focused: 'expiry' })}
                        validate={[this.validCardExpiration]}
                        format={formatExpiration}
                        normalize={normalizeNumber}
                        required={this.state.paymentType === 'creditCard'}
                        disabled={this.props.submitting}
                      />
                    </div>

                    {/* cvc */}
                    <div className="form-group col-sm-3 has-error">
                      <Field
                        label="CVC"
                        name="payment.creditCard.cvv"
                        autoComplete="cc-csc"
                        labelClassName="col-form-label col-form-label-lg"
                        className="form-control form-control-lg"
                        component={renderEnhancedField}
                        placeholder="CVC"
                        tooltip={`The CVC of the card being used for payment`}
                        maxLength="4"
                        onFocus={() => this.setState({ focused: 'cvc' })}
                        validate={[this.validCardCVC]}
                        required={this.state.paymentType === 'creditCard'}
                        disabled={this.props.submitting}
                      />
                    </div>
                  </div>

                  <>
                    {/* header */}
                    <div className="form-row mt-3 mb-0">
                      <div className="form-group col mb-0">
                        <h3>Billing Address</h3>
                      </div>
                    </div>

                    {/* address lines */}
                    <div className="form-row">
                      {/* address line 1 */}
                      <div className="form-group col-md-6 has-error">
                        <Field
                          type="text"
                          label="Address Line 1"
                          name="customer.address.street"
                          autoComplete="address-line1"
                          labelClassName="col-form-label col-form-label-lg"
                          className="form-control form-control-lg"
                          component={renderEnhancedField}
                          placeholder="Address Line 1"
                          tooltip={`Your street address`}
                          maxLength="255"
                          required={true}
                          disabled={this.props.submitting}
                        />
                      </div>

                      {/* address line 2 */}
                      <div className="form-group col-md-6 has-error">
                        <Field
                          type="text"
                          label="Address Line 2"
                          name="customer.address.street2"
                          autoComplete="address-line2"
                          labelClassName="col-form-label col-form-label-lg"
                          className="form-control form-control-lg"
                          component={renderEnhancedField}
                          placeholder="Address Line 2 (Optional)"
                          tooltip={`Any supplementary address information necessary to find you, like a suite or apartment number`}
                          maxLength="64"
                          required={false}
                          disabled={this.props.submitting}
                        />
                      </div>
                    </div>

                    {/* city/state/zip */}
                    <div className="form-row">
                      {/* city */}
                      <div className="form-group col-sm-4 has-error">
                        <Field
                          type="text"
                          label="City"
                          name="customer.address.city"
                          autoComplete="address-level2"
                          labelClassName="col-form-label col-form-label-lg"
                          className="form-control form-control-lg"
                          component={renderEnhancedField}
                          placeholder="City"
                          tooltip={`Your city`}
                          maxLength="64"
                          required={true}
                          disabled={this.props.submitting}
                        />
                      </div>

                      {/* state */}
                      <div className="form-group col-sm-5 has-error">
                        <Field
                          type="select"
                          label="State/Province"
                          name="customer.address.state"
                          autoComplete="address-level1"
                          labelClassName="col-form-label col-form-label-lg"
                          className="form-control form-control-lg"
                          component={renderEnhancedField}
                          placeholder="State/Province"
                          tooltip={`Your state or province`}
                          required={true}
                          disabled={this.props.submitting}
                        >
                          {this.renderStateOptions()}
                        </Field>
                      </div>

                      {/* zip */}
                      <div className="form-group col-sm-3 has-error">
                        <Field
                          type="text"
                          label="Postal Code"
                          name="customer.address.zip"
                          autoComplete="postal-code"
                          labelClassName="col-form-label col-form-label-lg"
                          className="form-control form-control-lg"
                          component={renderEnhancedField}
                          minLength="5"
                          maxLength="10"
                          pattern="^(\d{5})$|^(\d{5}-\d{4})$|^([A-Z]\d{1}[A-Z]-\d{1}[A-Z]\d{1})$"
                          placeholder="Postal Code"
                          tooltip={`Your postal code`}
                          format={formatZip}
                          required={true}
                          disabled={this.props.submitting}
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}

          {/* PayPal */}
          {this.state.payPalEnabled && this.state.payPalSupported && (
            <div className="card">
              <div
                className="card-header png-clickable png-reservation-book-payment-type"
                id="payPalHeader"
                aria-expanded="false"
                aria-controls="payPal"
                onClick={() => {
                  $('#payPal').collapse('show');
                  this.setState({ paymentType: 'payPal' });
                  this.props.setPaymentType('payPal');
                  this.props.clearField('payment.nonce');
                }}
              >
                Pay ${this.props.amount.toFixed(2)} with PayPal
              </div>
              <div
                id="payPal"
                className="collapse"
                aria-labelledby="payPalHeader"
                data-parent="#paymentTypes"
              >
                <div className="card-body text-center">
                  {!this.state.payPalClient && !this.state.payPalClientError && (
                    <span>Loading PayPal...</span>
                  )}
                  {this.state.payPalClientError && (
                    <span>
                      Error loading PayPal.{' '}
                      {this.state.creditCardEnabled ||
                      (this.state.applePayEnabled && this.state.applePaySupported) ||
                      (this.state.googlePayEnabled && this.state.googlePaySupported) ? (
                        <>Please use one of our other payment options.</>
                      ) : (
                        <>Please go back and select a "Pay Later" rate.</>
                      )}
                    </span>
                  )}
                  {this.state.paymentType === 'payPal' && this.props.nonce && (
                    <div>
                      <p>PayPal payment authorized. </p>
                      <p>You will be charged once you complete your reservation.</p>
                    </div>
                  )}
                  <div
                    className={
                      this.state.payPalClient && !this.state.payPalClientError ? '' : 'd-none'
                    }
                  >
                    {!this.props.nonce && (
                      <div>
                        <p>Press the button below to authorize payment via PayPal.</p>
                        <p>
                          Don't worry, you won't be charged until you complete your reservation.
                        </p>
                      </div>
                    )}
                    <div className={!this.props.nonce ? '' : 'd-none'}>
                      <div id="paypal-button" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Google Pay */}
          {this.state.googlePayEnabled && this.state.googlePaySupported && (
            <div className="card">
              <div
                className="card-header png-clickable png-reservation-book-payment-type"
                id="googlePayHeader"
                aria-expanded="false"
                aria-controls="googlePay"
                onClick={() => {
                  $('#googlePay').collapse('show');
                  this.setState({ paymentType: 'googlePay' });
                  this.props.setPaymentType('googlePay');
                  this.props.clearField('payment.nonce');
                }}
              >
                Pay ${this.props.amount.toFixed(2)} with Google Pay
              </div>
              <div
                id="googlePay"
                className="collapse"
                aria-labelledby="googlePayHeader"
                data-parent="#paymentTypes"
              >
                <div className="card-body text-center">
                  {!this.state.googlePayClient && !this.state.googlePayClientError && (
                    <span>Loading Google Pay...</span>
                  )}
                  {this.state.googlePayClientError && (
                    <span>
                      Error loading Google Pay.{' '}
                      {this.state.creditCardEnabled ||
                      (this.state.applePayEnabled && this.state.applePaySupported) ||
                      (this.state.payPalEnabled && this.state.payPalSupported) ? (
                        <>Please use one of our other payment options.</>
                      ) : (
                        <>Please go back and select a "Pay Later" rate.</>
                      )}
                    </span>
                  )}
                  {this.state.paymentType === 'googlePay' && this.props.nonce && (
                    <div>
                      <p>Google Pay payment authorized. </p>
                      <p>You will be charged once you complete your reservation.</p>
                    </div>
                  )}
                  <div
                    className={
                      this.state.googlePayClient && !this.state.googlePayClientError ? '' : 'd-none'
                    }
                  >
                    {!this.props.nonce && (
                      <div>
                        <p>Press the button below to authorize payment via Google Pay.</p>
                        <p>
                          Don't worry, you won't be charged until you complete your reservation.
                        </p>
                      </div>
                    )}
                    <div className={!this.props.nonce ? '' : 'd-none'}>
                      <div id="googlePay-button">
                        {/* load the script */}
                        <Script
                          url="https://pay.google.com/gp/p/js/pay.js"
                          onLoad={() => {
                            this.loadGooglePay(this.state.clientToken);
                          }}
                          onError={(e) => {
                            console.error('Error loading Google Pay script', e);
                            this.setState({ googlePayClientError: true });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Apple Pay */}
          {this.state.applePayEnabled && this.state.applePaySupported && (
            <div className="card">
              <div
                className="card-header png-clickable png-reservation-book-payment-type"
                id="applePayHeader"
                aria-expanded="false"
                aria-controls="applePay"
                onClick={() => {
                  $('#applePay').collapse('show');
                  this.setState({ paymentType: 'applePay' });
                  this.props.setPaymentType('applePay');
                  this.props.clearField('payment.nonce');
                }}
              >
                Pay ${this.props.amount.toFixed(2)} with Apple Pay
              </div>
              <div
                id="applePay"
                className="collapse"
                aria-labelledby="applePayHeader"
                data-parent="#paymentTypes"
              >
                <div className="card-body text-center">
                  {!this.state.applePayClient && !this.state.applePayClientError && (
                    <span>Loading Apple Pay...</span>
                  )}
                  {this.state.applePayClientError && (
                    <span>
                      Error loading Apple Pay.{' '}
                      {this.state.creditCardEnabled ||
                      (this.state.payPalEnabled && this.state.payPalSupported) ||
                      (this.state.googlePayEnabled && this.state.googlePaySupported) ? (
                        <>Please use one of our other payment options.</>
                      ) : (
                        <>Please go back and select a "Pay Later" rate.</>
                      )}
                    </span>
                  )}
                  {this.state.paymentType === 'applePay' && this.props.nonce && (
                    <div>
                      <p>Apple Pay payment authorized. </p>
                      <p>You will be charged once you complete your reservation.</p>
                    </div>
                  )}
                  <div
                    className={
                      this.state.applePayClient && !this.state.applePayClientError ? '' : 'd-none'
                    }
                  >
                    {!this.props.nonce && (
                      <div>
                        <p>Press the button below to authorize payment via Apple Pay.</p>
                        <p>
                          Don't worry, you won't be charged until you complete your reservation.
                        </p>
                      </div>
                    )}
                    <div className={!this.props.nonce ? '' : 'd-none'}>
                      <div
                        className="apple-pay-button apple-pay-button-white apple-pay-button-white-with-line"
                        onClick={() => {
                          // create a request
                          var paymentRequest = this.state.applePayClient.createPaymentRequest({
                            total: {
                              label: `Park 'N Go`,
                              amount: this.props.amount,
                            },
                          });

                          // create a session
                          var session = new window.ApplePaySession(3, paymentRequest);

                          // we need to bind to 'this' because we won't have it later
                          const instance = this;

                          // register the merchant validation callback
                          session.onvalidatemerchant = function (event) {
                            instance.state.applePayClient.performValidation(
                              {
                                validationURL: event.validationURL,
                                displayName: `Park 'N Go`,
                              },
                              function (err, merchantSession) {
                                if (err) {
                                  instance.props.applePayError();
                                } else {
                                  session.completeMerchantValidation(merchantSession);
                                }
                              },
                            );
                          };

                          // register the payment authorized callback
                          session.onpaymentauthorized = function (event) {
                            instance.state.applePayClient.tokenize(
                              {
                                token: event.payment.token,
                              },
                              function (tokenizeErr, payload) {
                                if (tokenizeErr) {
                                  // tell the session that it failed
                                  console.error('Error tokenizing Apple Pay', tokenizeErr);
                                  session.completePayment(window.ApplePaySession.STATUS_FAILURE);

                                  // tell the user that it failed
                                  instance.props.applePayError();
                                } else {
                                  // tell the session that it worked
                                  session.completePayment(window.ApplePaySession.STATUS_SUCCESS);

                                  // capture the nonce
                                  instance.props.dispatch(
                                    change(this.props.form, 'payment.nonce', payload.nonce),
                                  );
                                }
                              },
                            );
                          };

                          // fire it up!
                          session.begin();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Note on original payment, if applicable */}
        {this.props.original &&
        this.props.original.prepaid &&
        this.props.original.prepaid !== this.props.amount ? (
          <div className="row">
            <div className="col mt-3">
              <MessageBox flavor="info">
                You will be refunded ${this.props.original.prepaid.toFixed(2)} for the original
                reservation before being charged ${this.props.amount.toFixed(2)} for the new
                reservation.
              </MessageBox>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (this.state.paymentType === 'creditCard' &&
                  !this.props.submitFailed &&
                  (this.props.invalid || !this.state.htmlValid)) ||
                (this.state.paymentType !== 'creditCard' && !this.props.nonce) ||
                this.props.submitting
              }
            >
              {this.props.original ? 'Update' : 'Book'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
PayForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(PayForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // status
  status: state.context.status,

  // amount owed
  amount: formValueSelector(ownProps.form)(state, 'payment.amount')
    ? formValueSelector(ownProps.form)(state, 'payment.amount')
    : 0,

  // card details
  name: formValueSelector(ownProps.form)(state, 'payment.creditCard.name'),
  number: formValueSelector(ownProps.form)(state, 'payment.creditCard.number'),
  expiration: formValueSelector(ownProps.form)(state, 'payment.creditCard.expiration'),
  cvv: formValueSelector(ownProps.form)(state, 'payment.creditCard.cvv'),

  // non-CC nonce
  nonce: formValueSelector(ownProps.form)(state, 'payment.nonce'),

  // test mode?
  testMode: state.testMode.enabled || localStorage.getItem('testMode'),
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // loads the client payment token
  loadClientToken: () => {
    return dispatch(fetchClientToken()).catch((e) => {
      console.error('Error loading client payment token', e);
      return null;
    });
  },

  // stamps a payment type on the form
  setPaymentType(paymentType) {
    return dispatch(change(ownProps.form, 'payment.type', paymentType));
  },

  // clears a field on the form
  clearField(fieldName) {
    return dispatch(change(ownProps.form, fieldName, null));
  },

  // tells the user that there was an error with Apple Pay
  applePayError() {
    return dispatch(
      addAlert(
        'error',
        'There was an error using Apple Pay. Sorry about that. You can try again or use one of the other payment options.',
      ),
    );
  },
});

// turn this into a container component
PayForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(PayForm));

// set default props
PayForm.defaultProps = {
  form: 'reservationForm',
};

export default PayForm;
