import * as errors from './util/errors';
import { send as sendSOS } from './util/SOS/actions';

// defines options, interceptors, and handlers for Axios middleware
export const axiosOptions = {
  // we intercept all calls for logging and to inject the session token
  interceptors: {
    request: [
      ({ getState, dispatch, getSourceAction }, request) => {
        // log the request
        if (request.data) {
          console.debug(`Request being made to ${request.url} with data:`, request.data);
        } else {
          console.debug(`Request being made to ${request.url} with an empty payload`);
        }

        // add the client type
        request.headers['Client-Type'] = 'web-consumer';

        // if we're in test mode, note that on the request
        if (getState().testMode && getState().testMode.enabled) {
          request.headers['Test-Mode'] = 'true';
        }

        // propagate the request
        return request;
      },
    ],
  },

  /**
   * We handle our own errors on axios calls. If we receive one or more business errors
   * from the server, we simply propagate them. If we receive an unexpected status code,
   * we intercept it and create our own error to return. That way the client can always
   * expect a well-known response format.
   */
  onError: (bundle) => {
    // try to figure out the URL
    let url = null;
    if (
      bundle.action &&
      bundle.action.payload &&
      bundle.action.payload.request &&
      bundle.action.payload.request.url
    ) {
      url = bundle.action.payload.request.url;
    } else if (
      bundle.error &&
      bundle.error.response &&
      bundle.error.response.request &&
      bundle.error.response.request.responseURL
    ) {
      url = bundle.error.response.request.responseURL;
    }

    // try to figure out the payload
    let payload = '[unknown]';
    if (
      bundle.action &&
      bundle.action.payload &&
      bundle.action.payload.request &&
      bundle.action.payload.request.data
    ) {
      payload = JSON.stringify(bundle.action.payload.request.data);
    }

    // log the error
    if (url) {
      console.debug(
        'Error response (status ' +
          (bundle.error && bundle.error.response && bundle.error.response.status
            ? bundle.error.response.status
            : 'unknown') +
          `) received from request to ${url}`,
      );

      // if this is an error trying to send an SOS, we need to break out to avoid an infinite loop
      if (url.endsWith('sos')) {
        return;
      }
    } else {
      console.debug(
        'Error response (status ' +
          (bundle.error && bundle.error.response && bundle.error.response.status
            ? bundle.error.response.status
            : 'unknown') +
          `) received from request to unknown URL`,
      );
    }

    // we treat some response codes differently
    if (bundle.error && bundle.error.response) {
      switch (bundle.error.response.status) {
        // not authorized
        case 401:
          // send an SOS for this one
          bundle.dispatch(
            sendSOS(
              null,
              bundle.error.response.status +
                ' received on call to: ' +
                url +
                ' with payload: ' +
                payload,
            ),
          );

          // this will be handled later
          return {
            code: errors.UNAUTHORIZED,
            message: 'Authorization error',
          };

        // request couldn't be processed
        case 422:
          // send an SOS for this one
          bundle.dispatch(
            sendSOS(
              null,
              bundle.error.response.status +
                ' received on call to: ' +
                url +
                ' with payload: ' +
                payload,
            ),
          );
          break;

        // server errors
        case 500:
        case 501:
        case 503:
        case 506:
          // send an SOS for this one
          bundle.dispatch(
            sendSOS(
              null,
              bundle.error.response.status +
                ' received on call to: ' +
                url +
                ' with payload: ' +
                payload,
            ),
          );

          // this will be handled later
          return {
            code: errors.SERVER_ERROR,
            message: bundle.error.response.statusText
              ? bundle.error.response.statusText
              : 'Unknown server error',
          };
        default:
        // do nothing
      }
    }

    // do we have a business error embedded in the response?
    if (
      bundle.error &&
      bundle.error.response &&
      bundle.error.response.data &&
      bundle.error.response.data.code
    ) {
      // let the errors fall through for rejection
      return bundle.error.response.data;
    } else if (bundle.error && bundle.error.message) {
      // there are some we treat differently
      switch (bundle.error.message) {
        case 'Network Error':
          // send an SOS for this one
          bundle.dispatch(
            sendSOS(
              null,
              'Network error received on call to: ' + url + ' with payload: ' + payload,
            ),
          );

          // let the caller handle it
          return {
            code: errors.NETWORK_ERROR,
            message: 'Unknown network error',
          };
        default:
          // do nothing
          break;
      }
    }

    // let the error fall through for rejection
    if (bundle.error) {
      return bundle.error;
    }

    // this shouldn't happen
    return bundle;
  },

  // we want to check successful responses for errors too
  onSuccess: (bundle) => {
    // log the response
    if (!bundle.response || !bundle.response.data) {
      console.debug(
        'Empty response received from request to ' +
          (bundle.response.request && bundle.response.request.responseURL
            ? bundle.response.request.responseURL
            : 'unknown URL'),
      );
    } else if (bundle.response && bundle.response.data) {
      console.debug(
        'Response received from request to ' +
          (bundle.response.request && bundle.response.request.responseURL
            ? bundle.response.request.responseURL
            : 'unknown URL'),
        bundle.response.data,
      );
    }

    // check for business errors
    if (bundle.response && bundle.response.data && bundle.response.data.errors) {
      return Promise.reject(bundle.response.data.errors);
    }

    // default
    if (bundle.response && bundle.response.data) {
      return bundle.response.data;
    } else {
      return null;
    }
  },

  // we want errors to result in a rejected promise
  returnRejectedPromiseOnError: true,
};
