// a mock facility for test mode
const mockFacility = {
  id: 2147483647,
  code: 'TST',
  shuttleCode: 'TST',
  netParkCode: 2147483647,
  name: 'Sandbox',
  address: {
    street: '916 W. State St.',
    city: 'Geneva',
    state: 'IL',
    zip: '60134',
  },
  phone: 7048675309,
  email: 'parkngo@levelxt.com',
  notes: '',
  open: true,
  location: {
    latitude: 41.888909,
    longitude: -88.316624,
  },
  timeZone: 'America/Chicago',
  pointsOfInterest: [
    {
      name: '7th Street Park',
      location: {
        latitude: 41.886018,
        longitude: -88.3132,
      },
    },
  ],
  about:
    "This is a test location. I wouldn't recommend trying to park here. You'll only be disappointed.",
};

/**
 * Fetches all open facilities.
 */
export function fetchOpen(test = false) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // if we are in test mode, we mock out a single facility
    if (test) {
      return Promise.resolve([mockFacility]);
    } else {
      // make the call
      return dispatch({
        type: 'FETCH_FACILITIES_OPEN',
        payload: {
          request: {
            method: 'get',
            url: `/facilities?open=true`,
          },
        },
      }).then((response) => {
        return response;
      });
    }
  };
}

/**
 * Fetches a single facility by ID.
 */
export function fetchById(facilityId, test = false) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // if we are in test mode, we mock out a single facility
    if (test) {
      return Promise.resolve(mockFacility);
    } else {
      // make the call
      return dispatch({
        type: 'FETCH_FACILITY',
        payload: {
          request: {
            method: 'get',
            url: `/facilities/${facilityId}`,
          },
        },
      }).then((response) => {
        return response;
      });
    }
  };
}

/**
 * Fetches all services for a facility.
 */
export function fetchServices(facilityId, start, end) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // build the query parameters
    var params = `?start=${start}&end=${end}`;

    // make the call
    return dispatch({
      type: 'FETCH_FACILITY_SERVICES',
      payload: {
        request: {
          method: 'get',
          url: `/facilities/${facilityId}/services` + params,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}

/**
 * Fetches quotes for a reservation. Note that services is a comma-separated
 * list of service IDs retrieved from fetchServices().
 */
export function fetchQuotes(
  facilityId,
  start,
  end,
  customerId = null,
  services = null,
  fppRedeemed = 0,
) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // build the query parameters
    var params = `?start=${start}&end=${end}`;
    if (customerId) {
      params += `&customerId=${customerId}`;
      params += `&fppRedeemed=${fppRedeemed}`;
    }
    if (services) {
      // need a comma-separated list of IDs
      var list = '';
      for (var i = 0; i < services.length; i++) {
        if (list.length > 0) {
          list += ',';
        }
        list += String(services[i].id);
      }
      params += `&services=${list}`;
    }

    // make the call
    return dispatch({
      type: 'FETCH_FACILITY_QUOTES',
      payload: {
        request: {
          method: 'get',
          url: `/facilities/${facilityId}/quotes` + params,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}
